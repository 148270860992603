import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Button, CircularProgress, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PaymentIcon from '@mui/icons-material/Payment';
import { useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

const Header = ({ user, handleDrawerToggle }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleBuyClick = () => {
    setLoading(false);
    navigate('/pricing');
    amplitude.track('Buy Time Clicked from Header', { userId: user?.uid });
  };

  const handleMenuClick = () => {
    handleDrawerToggle();
    amplitude.track('Menu Icon Clicked from Header', { userId: user?.uid });
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#1976d2', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleMenuClick}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          {user?.photoURL && (
            <Avatar
              src={user.photoURL}
              alt="User Profile"
              sx={{
                width: 32,
                height: 32,
                border: '2px solid white',
                cursor: 'pointer',
                display: { xs: 'block', sm: 'none' }, // Visible only on mobile
              }}
            />
          )}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' }, fontSize: { sm: '1.5rem' } }}
          >
            Xosum.AM
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            startIcon={loading ? <CircularProgress size={24} color="inherit" /> : <PaymentIcon />}
            sx={{
              color: '#fff',
              backgroundColor: '#ff5722',
              paddingY: 1,
              paddingX: { xs: 2, sm: 3 },
              fontSize: { xs: '0.9rem', sm: '1rem' },
              minWidth: 'auto',
              '&:hover': { backgroundColor: '#e64a19' },
            }}
            onClick={handleBuyClick}
            disabled={loading}
          >
            {loading ? '' : 'ԳՆԵԼ ԺԱՄԱՆԱԿ'}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
