// src/components/DeleteRecordingDialog.js

import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';

const DeleteRecordingDialog = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Ջնջել ձայնագրությունը</DialogTitle>
      <Typography sx={{ px: 3, py: 1 }}>
        Այս գործողությունը անդառնալի է, և ձայնագրությունը չի կարող վերականգնվել։
      </Typography>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Չեղարկել
        </Button>
        <Button onClick={onConfirm} color="error">
          Ջնջել
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRecordingDialog;
