import React from 'react';
import { Grid, Typography, Chip, Box, LinearProgress, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatDuration, formatDate } from '../utils';

const RecordingMetadata = ({
  filePath,
  docId,
  duration,
  createdAt,
  fileType,
  status
}) => {
  const navigate = useNavigate();
  const isLongDuration = duration > 120;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="body2">Անուն:</Typography>
          <Typography variant="subtitle2">
            {filePath ? filePath.split('/').pop() : docId}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Տևողություն:</Typography>
          <Typography variant="subtitle2">{formatDuration(duration)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Ստեղծվել է:</Typography>
          <Typography variant="subtitle2">{formatDate(createdAt)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Ֆորմատ:</Typography>
          <Typography variant="subtitle2">{fileType}</Typography>
        </Grid>
      </Grid>
      <Chip
        label={
          status === 'transcribed'
            ? 'Վերծանված'
            : status === 'processing'
            ? 'Ընթացքում է'
            : status === 'uploading'
            ? 'Վերբեռնվում է'
            : 'Խափանված'
        }
        color={
          status === 'transcribed'
            ? 'success'
            : status === 'processing'
            ? 'warning'
            : status === 'uploading'
            ? 'info'
            : 'error'
        }
        sx={{ mt: 2 }}
        variant="outlined"
      />
      {status === 'processing' && isLongDuration && (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography variant="h5" color="text.primary" sx={{ fontWeight: 'bold' }}>
            Խոսքը վերածվում է տեքստի։ Քանի որ ձայնագրությունը երկար է,
            խնդրում ենք փակել այս էջը և վերադառնալ ավելի ուշ։
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate('/upload')}
            >
              Վերբեռնել նոր ձայնագրություն
            </Button>
          </Box>
        </Box>
      )}
      {status === 'processing' && !isLongDuration && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Խոսքը դարձնում ենք տեքստ։ Խնդրում ենք սպասել։
          </Typography>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};

export default RecordingMetadata;
