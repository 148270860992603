// src/components/RecordingHeader.js

import React from 'react';
import { Stack, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const RecordingHeader = ({
  onBack,
  onOpenMenu,
  onCloseMenu,
  anchorEl,
  onDelete,
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ mb: 4 }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <IconButton
          onClick={onBack}
          sx={{
            backgroundColor: (theme) => theme.palette.grey[100],
            padding: 2,
            '&:hover': {
              backgroundColor: (theme) => theme.palette.grey[200],
            },
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" component="h1">
          Ձայնագրության Մանրամասները
        </Typography>
      </Stack>
      <IconButton onClick={onOpenMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onCloseMenu}>
        <MenuItem onClick={onDelete}>Ջնջել</MenuItem>
      </Menu>
    </Stack>
  );
};

export default RecordingHeader;
