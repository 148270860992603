import React, { useState } from 'react';
import {
  Box,
  LinearProgress,
  Paper,
  Container,
  Typography,
  Card,
  CardContent,
  Tabs,
  Tab,
  Badge,
  Button,
  CircularProgress
} from '@mui/material';
import { Article, Chat, QuestionAnswer, Subtitles, Translate, LocalHospital } from '@mui/icons-material';
import { useParams, useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import useRecordingDetails from '../hooks/useRecordingDetails';
import RecordingHeader from '../components/RecordingHeader';
import TranscriptionSection from '../components/TranscriptionSection';
import RecordingMetadata from '../components/RecordingMetadata';
import AudioPlayerSection from '../components/AudioPlayerSection';
import WordSRTDownloadSection from '../components/WordSRTDownloadSection';
import DeleteRecordingDialog from '../components/DeleteRecordingDialog';
import ActionableTranscriptionSection from '../components/ActionableTranscriptionSection';
import * as amplitude from '@amplitude/analytics-browser';

const RecordingDetails = () => {
  const { docId } = useParams();
  const decodedDocId = decodeURIComponent(docId);
  const navigate = useNavigate();

  const {
    recordingDetails,
    loading,
    error,
    audioUrl,
    wordSRTUrl,
    deleteRecording,
    updateRecording,
  } = useRecordingDetails(decodedDocId, navigate);

  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [copyStatus, setCopyStatus] = useState('Պատճենել');
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text);
    setCopyStatus('Պատճենված է');
    amplitude.track('Text Copied', { docId });
    setTimeout(() => setCopyStatus('Պատճենել'), 2000);
  };

  const openMenu = (event) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
    closeMenu();
    amplitude.track('Delete Dialog Opened', { docId });
  };

  const closeDeleteDialog = () => setDeleteDialogOpen(false);

  const handleDeleteRecording = async () => {
    await deleteRecording(recordingDetails);
  };

  if (loading) {
    return (
      <Box sx={{ width: '100%', mt: 4 }}>
        <LinearProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md">
        <Paper
          elevation={3}
          sx={{
            p: 4,
            mt: 4,
            textAlign: 'center',
            backgroundColor: '#fff5f5'
          }}
        >
          <Typography variant="h6" color="error">
            {error}
          </Typography>
        </Paper>
      </Container>
    );
  }

  if (!recordingDetails) {
    return (
      <Container maxWidth="md">
        <Paper
          elevation={3}
          sx={{
            p: 4,
            mt: 4,
            textAlign: 'center',
            backgroundColor: '#f5f5f5'
          }}
        >
          <Typography variant="h6" color="textSecondary">
            No recording details available.
          </Typography>
        </Paper>
      </Container>
    );
  }

  const { status, filePath, createdAt, duration, fileType } = recordingDetails;
  const isTranscribed = status && status.toLowerCase() === 'transcribed';

  return (
    <Container maxWidth="lg">
      <Box sx={{ mb: 3, mt: 2 }}>
        <RecordingHeader
          onBack={() => navigate(-1)}
          onOpenMenu={openMenu}
          onCloseMenu={closeMenu}
          anchorEl={anchorEl}
          onDelete={openDeleteDialog}
        />

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          sx={{ mb: 1 }}
        >
          <Tab
            icon={<Article />}
            label="Բնօրինակ"
            sx={{ fontWeight: 'bold' }}
          />
          <Tab
            icon={
              <Badge color="error" badgeContent="ՆՈՐ">
                <Translate />
              </Badge>
            }
            label="Թարգմանություն"
            sx={{ fontWeight: 'bold' }}
          />
          <Tab
            icon={
              <Badge color="error" badgeContent="ՆՈՐ">
                <QuestionAnswer />
              </Badge>
            }
            label="Հարցազրույց"
            sx={{ fontWeight: 'bold' }}
          />
          <Tab
            icon={<Subtitles />}
            label="Ենթագրեր"
            sx={{ fontWeight: 'bold' }}
          />
          {/*
          <Tab
            icon={<LocalHospital />}
            label="Բժշկական"
            sx={{ fontWeight: 'bold' }}
          />
          */}
        </Tabs>

        {tabValue === 0 && (
          <Card elevation={3}>
            <CardContent>
              <TranscriptionSection
                title="Վերծանված տեքստ"
                transcription={recordingDetails.transcription}
                copyStatus={copyStatus}
                onCopy={handleCopyText}
              />
              <Box sx={{ mt: 3 }}>
                <RecordingMetadata
                  filePath={filePath}
                  docId={decodedDocId}
                  duration={duration}
                  createdAt={createdAt}
                  fileType={fileType}
                  status={status}
                />
              </Box>
              <AudioPlayerSection audioUrl={audioUrl} />
            </CardContent>
          </Card>
        )}

        {tabValue === 1 && (
          <Card elevation={3}>
            <CardContent>
              <ActionableTranscriptionSection
                docId={decodedDocId}
                field="translation"
                title="Թարգմանություն"
                firebaseFunctionName="makeTranslation"
                trackEvent="Translation Created"
                icon={Translate}
                emptyTitle="Թարգմանություն"
                emptyDescription="Այստեղ կարող եք թարգմանել վերծանված տեքստը այլ լեզուներով:"
                buttonText="Թարգմանել"
                transcription={recordingDetails.translation}
                copyStatus={copyStatus}
                onCopy={handleCopyText}
                onSave={(newText) => updateRecording({ translation: newText })}
                isTranscribed={isTranscribed}
                withLanguageSelector
                inProgress={recordingDetails.translationInProgress}
              />
            </CardContent>
          </Card>
        )}

        {tabValue === 2 && (
          <Card elevation={3}>
            <CardContent>
              <ActionableTranscriptionSection
                docId={decodedDocId}
                field="interview"
                title="Հարցազրույց"
                firebaseFunctionName="makeInterview"
                trackEvent="Interview Created"
                icon={QuestionAnswer}
                emptyTitle="Դարձնել հարցազրույց"
                emptyDescription="Եթե վերծանել եք հարցազրույց երկու անձի միջև, կարող եք սրբագրել ու դարձնել ավելի ընթեռնելի։"
                buttonText="Դարձնել հարցազրույց"
                transcription={recordingDetails.interview}
                copyStatus={copyStatus}
                onCopy={handleCopyText}
                onSave={(newText) => updateRecording({ interview: newText })}
                isTranscribed={isTranscribed}
                inProgress={recordingDetails.interviewInProgress}
              />
            </CardContent>
          </Card>
        )}
        {tabValue === 3 && (
          <Card elevation={3}>
            <CardContent>
              {wordSRTUrl ? (
                <Box>
                  <WordSRTDownloadSection wordSRTUrl={wordSRTUrl} />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    p: 3
                  }}
                >
                  <Box sx={{ mb: 2 }}>
                    <Subtitles sx={{ fontSize: 80, color: 'text.secondary' }} />
                  </Box>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Ֆայլը դեռ մշակվում է
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
                    Խնդրում ենք սպասել ենթագրերի պատրաստ լինելուն
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        )}

        {/*
        {tabValue === 4 && (
          <Card elevation={3}>
            <CardContent>
              <ActionableTranscriptionSection
                docId={decodedDocId}
                field="medical"
                title="Բժշկական հաշվետվություն"
                firebaseFunctionName="makeMedicalReport"
                trackEvent="Medical Report Created"
                icon={LocalHospital}
                emptyTitle="Ստեղծել բժշկական հաշվետվություն"
                emptyDescription="Ստեղծեք սոնոգրաֆիա հաշվետվություն՝ հիմնված տեքստի վրա։"
                buttonText="Ստեղծել հաշվետվություն"
                transcription={recordingDetails.medical}
                copyStatus={copyStatus}
                onCopy={handleCopyText}
                onSave={(newText) => updateRecording({ medical: newText })}
                isTranscribed={isTranscribed}
              />
            </CardContent>
          </Card>
        )}
        */}

        <DeleteRecordingDialog
          open={deleteDialogOpen}
          onClose={closeDeleteDialog}
          onConfirm={handleDeleteRecording}
        />
      </Box>
    </Container>
  );
};

export default RecordingDetails;
