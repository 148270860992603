// src/components/AudioPlayerSection.js

import React from 'react';
import { Box, Typography, Paper, Divider } from '@mui/material';

const AudioPlayerSection = ({ audioUrl }) => {
  if (!audioUrl) return null;

  return (
    <>
      {/* Increase margin-top on the Divider to separate from the previous chip */}
      <Divider sx={{ mt: 3 }} />
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" gutterBottom>
          Լսել Ձայնագրությունը
        </Typography>
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            backgroundColor: (theme) => theme.palette.grey[50],
          }}
        >
          <audio controls style={{ width: '100%' }}>
            <source src={audioUrl} type="audio/webm" />
            Ձեր զննարկիչը չի աջակցում աուդիո նվագարկմանը:
          </audio>
        </Paper>
      </Box>
    </>
  );
};

export default AudioPlayerSection;
