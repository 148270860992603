import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import * as amplitude from '@amplitude/analytics-browser';
import TranscriptionSection from './TranscriptionSection';

const ActionableTranscriptionSection = ({
  docId,
  field,
  title,
  firebaseFunctionName,
  trackEvent,
  icon: Icon,
  emptyTitle,
  emptyDescription,
  buttonText,
  transcription,
  copyStatus,
  onCopy,
  onSave,
  isTranscribed,
  withLanguageSelector = false,
  inProgress = false
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const functions = getFunctions();
  const firestore = getFirestore();

  useEffect(() => {
    if (transcription && inProgress && (field === 'translation' || field === 'interview')) {
      const progressField = field === 'translation' ? 'translationInProgress' : 'interviewInProgress';
      updateDoc(doc(firestore, 'recordings', docId), { [progressField]: false }).catch(console.error);
    }
  }, [transcription, inProgress, field, firestore, docId]);

  const languageOptions = [
    { code: 'en', label: '🇺🇸 Անգլերեն' },
    { code: 'es', label: '🇪🇸 Իսպաներեն' },
    { code: 'fr', label: '🇫🇷 Ֆրանսերեն' },
    { code: 'de', label: '🇩🇪 Գերմաներեն' },
    { code: 'ja', label: '🇯🇵 Ճապոներեն' },
    { code: 'fa', label: '🇮🇷 Պարսկերեն' },
    { code: 'ar', label: '🇸🇦 Արաբերեն' },
    { code: 'ru', label: '🇷🇺 Ռուսերեն' },
    { code: 'hy', label: '🇦🇲 Հայերեն' }
  ];

  const handleAction = async () => {
    try {
      setLoading(true);
      if (field === 'translation' || field === 'interview') {
        const progressField = field === 'translation' ? 'translationInProgress' : 'interviewInProgress';
        await updateDoc(doc(firestore, 'recordings', docId), {
          [progressField]: true
        });
      }
      const func = httpsCallable(functions, firebaseFunctionName);
      const params = withLanguageSelector ? { docId, language: selectedLanguage } : { docId };
      await func(params);
      amplitude.track(trackEvent, { docId });
    } catch (error) {
      console.error(`Error creating ${field}:`, error);
    } finally {
      setLoading(false);
    }
  };

  if (transcription) {
    return (
      <TranscriptionSection
        title={title}
        transcription={transcription}
        copyStatus={copyStatus}
        onCopy={onCopy}
        onSave={onSave}
      />
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        p: 3
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Icon sx={{ fontSize: 80, color: 'text.secondary' }} />
      </Box>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {emptyTitle}
      </Typography>
      <Typography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
        {emptyDescription}
      </Typography>
      {withLanguageSelector && (
        <FormControl sx={{ mb: 3, minWidth: 180 }} size="small">
          <InputLabel id="language-select-label">Լեզու</InputLabel>
          <Select
            labelId="language-select-label"
            id="language-select"
            value={selectedLanguage}
            label="Լեզու"
            onChange={(e) => setSelectedLanguage(e.target.value)}
          >
            {languageOptions.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {isTranscribed && (
        <>
          <Button
            variant="contained"
            color="primary"
            disabled={loading || inProgress}
            onClick={handleAction}
            sx={{
              minWidth: 240,
              padding: '10px 24px',
              fontSize: '1.1rem',
              position: 'relative'
            }}
          >
            <Box sx={{ opacity: loading || inProgress ? 0 : 1, display: 'flex', alignItems: 'center' }}>
              {buttonText}
            </Box>
            {(loading || inProgress) && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              >
                <CircularProgress size={24} color="inherit" />
              </Box>
            )}
          </Button>
          {(loading || inProgress) && (
            <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
              Կարող է տևել երկար, կախված ձայնագրության երկարությունից։ 
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default ActionableTranscriptionSection;
