// src/utils.js

export const formatDuration = (seconds = 0) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours > 0 ? `${hours} ժ ` : ''}${minutes > 0 ? `${minutes} ր ` : ''}${secs} վրկ`;
  };
  
  export const formatDate = (timestamp) => {
    const createdDate = timestamp.toDate();
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
  
    const time = createdDate.toLocaleTimeString('hy-AM', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  
    if (
      createdDate.getDate() === today.getDate() &&
      createdDate.getMonth() === today.getMonth() &&
      createdDate.getFullYear() === today.getFullYear()
    ) {
      return `Այսօր ${time}`;
    } else if (
      createdDate.getDate() === yesterday.getDate() &&
      createdDate.getMonth() === yesterday.getMonth() &&
      createdDate.getFullYear() === yesterday.getFullYear()
    ) {
      return `Երեկ ${time}`;
    }
  
    const day = String(createdDate.getDate()).padStart(2, '0');
    const month = String(createdDate.getMonth() + 1).padStart(2, '0');
    const year = createdDate.getFullYear();
    return `${day}.${month}.${year} ${time}`;
  };
  