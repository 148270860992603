// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyA3hubxRWd_sAzzgHcYt7lkchUupFyb_AM",
    authDomain: "app.xosum.am",
    projectId: "xosumam-26040",
    storageBucket: "xosumam-26040.appspot.com",
    messagingSenderId: "193242452550",
    appId: "1:193242452550:web:d696738847beba28f43047",
    measurementId: "G-1NCVGZGYVC"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app);
const db = getFirestore(app); 
const functions = getFunctions(app); 

export { auth, provider, signInWithPopup, storage, db, functions };
