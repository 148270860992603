import React from 'react';
import { Box, Typography, Button, Divider, Paper, Stack } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import VimeoIcon from '@mui/icons-material/OndemandVideo';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';

const WordSRTDownloadSection = ({ wordSRTUrl }) => {
  if (!wordSRTUrl) return null;

  return (
    <>
      <Paper elevation={2} sx={{ mt: 3, p: 3, borderRadius: 2 }}>
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          textAlign: 'center' 
        }}>
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{
              fontWeight: 600,
              color: 'primary.main',
              mb: 2
            }}
          >
            Ներբեռնել SRT Ֆայլը Reel-ի համար
          </Typography>

          <Stack 
            direction="row" 
            spacing={2} 
            sx={{ mb: 3 }}
            alignItems="center"
          >
            <InstagramIcon sx={{ color: '#E4405F', fontSize: 32 }} />
            <SmartDisplayIcon sx={{ color: '#000000', fontSize: 32 }} /> {/* TikTok icon replacement */}
            <YouTubeIcon sx={{ color: '#FF0000', fontSize: 32 }} />
          </Stack>
          
          <Button
            variant="contained"
            color="primary"
            href={wordSRTUrl}
            download
            startIcon={<DownloadIcon />}
            sx={{
              px: 4,
              py: 1.5,
              borderRadius: 2,
              textTransform: 'none',
              fontSize: '1.1rem',
              boxShadow: 2,
              '&:hover': {
                boxShadow: 4,
                transform: 'translateY(-2px)',
                transition: 'all 0.2s ease-in-out'
              }
            }}
          >
            Ներբեռնել
          </Button>
        </Box>
      </Paper>

      <Paper 
        elevation={2} 
        sx={{ 
          mt: 2, 
          p: 3, 
          borderRadius: 2,
          bgcolor: 'grey.50'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          textAlign: 'center' 
        }}>
          <Typography 
            variant="h6" 
            gutterBottom
            sx={{
              fontWeight: 600,
              color: 'text.secondary',
              mb: 2
            }}
          >
            Շուտով
          </Typography>

          <Stack 
            direction="row" 
            spacing={2} 
            sx={{ mb: 2 }}
            alignItems="center"
          >
            <YouTubeIcon sx={{ color: '#FF0000', fontSize: 32 }} />
            <VimeoIcon sx={{ color: '#1AB7EA', fontSize: 32 }} />
          </Stack>

          <Typography 
            variant="body1" 
            color="text.secondary"
            sx={{ fontStyle: 'italic' }}
          >
            YouTube-ի և Vimeo-ի ենթագրերը հասանելի կլինեն շուտով
          </Typography>
        </Box>
      </Paper>
    </>
  );
};

export default WordSRTDownloadSection;