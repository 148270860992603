import React, { useState, useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { 
  Button,
  CircularProgress,
  Grid,
  Typography,
  Box,
  Paper,
  Link,
  Chip,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Pricing = () => {
  const [stripeSessionLoading, setStripeSessionLoading] = useState(false);

  const handleUpgrade = () => {
    setStripeSessionLoading(true);
    window.location.href = 'https://buy.stripe.com/14k9Bk1dJdlX8s8000';
  };

  useEffect(() => {
    amplitude.track('Pricing Page Loaded');
  }, []);

  const featureExplanations = {
    'Telegram բոտ ձայնային վերծանման համար': 'Մեր Telegram բոտը թույլ է տալիս ուղարկել ձայնային հաղորդագրություններ և ստանալ դրանց տեքստային տարբերակը',
    'Հարցազրույցների և հեռախոսազանգերի վերծանում': 'Ավտոմատ կերպով առանձնացնում է խոսակցության մասնակիցներին և ստեղծում է հարցազրույցի տեքստային տարբերակը',
    'Telegram-ի ձայնային հաղորդագրության համառոտագրում': 'Ստեղծում է ձայնագրության հիմնական կետերի ամփոփում',
    'Հոդվածի գեներացում': 'Ստեղծում է SEO-օպտիմիզացված հոդված ձայնագրության հիման վրա'
  };

  const faqs = [
    {
      question: 'Ինչպե՞ս է հաշվարկվում վերծանման ժամանակը',
      answer: 'Վերծանման ժամանակը հաշվարկվում է ըստ ձայնագրության իրական տևողության: Օրինակ, 10 րոպեանոց ձայնագրությունը հաշվարկվում է որպես 10 րոպե:'
    },
    {
      question: 'Կարո՞ղ եմ փոխել իմ պլանը',
      answer: 'Այո, դուք կարող եք ցանկացած պահի փոխել ձեր պլանը: Եթե անցնեք ավելի թանկ պլանի, ապա կվճարեք միայն տարբերությունը:'
    },
    {
      question: 'Արդյո՞ք իմ վճարումը ապահով է',
      answer: 'Վճարումների համար մենք օգտագործում ենք Stripe համակարգը, որը աշխարհի ամենամեծ վճարահաշվարկային համակարգն է։ Այն ունի բանկային մակարդակի անվտանգություն և օգտագործվում է աշխարհի ամենաառաջատար ընկերությունների կողմից։'
    },
    {
      question: 'Ի՞նչ վճարման եղանակներ են ընդունվում',
      answer: 'Մենք ընդունում ենք բոլոր հիմնական վճարային քարտերը (Visa, Mastercard, American Express), Apple Pay, Google Pay, Stripe Link"'
    }
  ];

  const Feature = ({ text, info }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
      <Typography variant="body1">
        {text}
      </Typography>
      {info && (
        <Tooltip title={info} arrow placement="top">
          <IconButton size="small" sx={{ ml: 1 }}>
            <InfoIcon fontSize="small" color="action" />
          </IconButton>
        </Tooltip>
      )}
      {text.includes('տեստ') && (
        <Chip
          label="Շուտով"
          size="small"
          color="primary"
          sx={{ ml: 1 }}
        />
      )}
    </Box>
  );

  const plans = [
    {
      name: 'Free',
      price: 'Անվճար',
      description: 'Սկսեք անվճար և տեսեք, թե ինչպես է աշխատում մեր ծառայությունը',
      features: [
        'Անսահմանափակ թելադրություն',
        '5 րոպե վերծանում',
        'Ձայնագրություն բջջայինով',
        'Telegram բոտ ձայնային վերծանման համար',
        'Telegram-ի ձայնային հաղորդագրության համառոտագրում',
      ],
      highlighted: false,
    },
    {
      name: 'Audio Pro',
      price: '$9/ամիս',
      description: 'Լրագրողների, սոցիոլոգների ու զբաղված պրոֆեսիոնալների համար',
      features: [
        'Ամեն ինչ Free պլանում',
        '2 ժամ վերծանում ամսեկան',
        'Հնարավորություն գնել ավել ժամանակ',
        'Աուդիո ֆայլի վերբեռնում',
        'Վերծանում 9 լեզվով',
        'Թարգմանություն 9 լեզվով',
        'Հարցազրույցների և հեռախոսազանգերի վերծանում',
      ],
      highlighted: true,
      cta: {
        label: 'ԸՆՏՐԵԼ PRO',
        action: handleUpgrade,
      }
    },
    {
      name: 'Media Business',
      price: 'Կապնվեք մեզ հետ',
      description: 'Վիդեո և աուդիո պրոֆեսիոնալների համար, որոնք ստեղծում են բարձր որակի հայերեն բովանդակություն',
      features: [
        'Ամեն ինչ Pro պլանում',
        '5 ժամ վերծանում ամսեկան',
        'Հնարավորություն գնել ավել ժամանակ',
        'Վիդեո վերբեռնում',
        'Ստեղծել ենթագրեր reels-ի համար',
        'Վերծանություն YouTube-ի համար',
        'Ենթագրերի թարգմանություն',
        'Տեքստից խոսքի գեներացում',
        'Հոդվածի գեներացում',
      ],
      cta: {
        label: 'Կապնվեք մեզ հետ',
        action: () => window.open('https://tally.so/r/3Evk5L', '_blank'),
      }
    }
  ];

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto', px: 2, py: 4 }}>
      <Typography 
        variant="h3" 
        align="center" 
        sx={{ 
          mb: 4,
          fontSize: { xs: '1.75rem', sm: '2.25rem', md: '2.5rem' }
        }}
      >
        Ընտրեք ձեզ հարմար պլանը
      </Typography>

      <Grid 
        container 
        spacing={2} 
        justifyContent="center"
        sx={{ mb: 4 }}
      >
        {plans.map((plan, idx) => (
          <Grid item xs={12} sm={6} md={4} key={idx}>
            <Paper
              elevation={plan.highlighted ? 8 : 1}
              sx={{
                position: 'relative',
                p: { xs: 2, sm: 3 },
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                border: plan.highlighted ? 2 : 1,
                borderColor: plan.highlighted ? 'primary.main' : 'grey.200',
              }}
            >
              {plan.highlighted && (
                <Chip
                  label="Ամենապահանջված"
                  color="primary"
                  sx={{
                    position: 'absolute',
                    top: -16,
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                />
              )}

              <Typography variant="h4" component="h2" gutterBottom>
                {plan.name}
              </Typography>
              
              <Typography variant="body1" color="text.secondary" paragraph>
                {plan.description}
              </Typography>

              <Typography variant="h4" gutterBottom>
                {plan.price}
              </Typography>

              <Box sx={{ my: 4, flexGrow: 1 }}>
                {plan.features.map((feature, featureIdx) => (
                  <Feature 
                    key={featureIdx} 
                    text={feature}
                    info={featureExplanations[feature]}
                  />
                ))}
              </Box>

              {plan.cta && (
                <Button
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={plan.cta.action}
                  disabled={stripeSessionLoading}
                  sx={{ mt: 'auto' }}
                >
                  {stripeSessionLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    plan.cta.label
                  )}
                </Button>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ 
        textAlign: 'center', 
        mb: 8,
        mt: 6,
        py: 3,
        borderTop: 1,
        borderBottom: 1,
        borderColor: 'divider'
      }}>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Անհրաժե՞շտ են լրացուցիչ րոպեներ։
        </Typography>
        <Link 
          href="https://tally.so/r/3Evk5L" 
          target="_blank"
          underline="hover"
        >
          Կապնվեք մեզ
        </Link>
      </Box>

      <Box sx={{ 
        mt: 4,
        pt: 4,
        borderTop: '1px solid',
        borderColor: 'divider'
      }}>
        <Typography 
          variant="h5" 
          gutterBottom 
          align="center"
          sx={{ mb: 4 }}
        >
          Հաճախ տրվող հարցեր
        </Typography>
        <Box sx={{ maxWidth: 800, mx: 'auto' }}>
          {faqs.map((faq, index) => (
            <Accordion 
              key={index} 
              sx={{ 
                '&:not(:last-child)': { mb: 1 },
                '&:before': { display: 'none' },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" color="text.secondary">
                  {faq.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Pricing;