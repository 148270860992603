// src/hooks/useRecordingDetails.js

import { useState, useEffect } from 'react';
import { doc, onSnapshot, deleteDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, deleteObject } from 'firebase/storage';
import { storage, db } from '../firebase';
import * as amplitude from '@amplitude/analytics-browser';

export default function useRecordingDetails(docId, navigate) {
  const [recordingDetails, setRecordingDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [wordSRTUrl, setWordSRTUrl] = useState(null);

  useEffect(() => {
    amplitude.track('Recording Details Page Viewed', { docId });

    let isMounted = true;
    const docRef = doc(db, 'recordings', docId);

    const unsubscribe = onSnapshot(
      docRef,
      (docSnapshot) => {
        if (!isMounted) return;

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setRecordingDetails(data);
          setLoading(false);

          amplitude.track('Recording Data Fetched', { docId, status: data.status });

          if (data.status === 'transcribed' && data.filePath) {
            // Fetch audio URL
            const audioRef = ref(storage, data.filePath);
            getDownloadURL(audioRef)
              .then((url) => {
                if (isMounted) {
                  setAudioUrl(url);
                  amplitude.track('Audio URL Retrieved', { docId });
                }
              })
              .catch((error) => {
                console.error('Error fetching audio URL:', error);
                amplitude.track('Error Fetching Audio URL', {
                  docId,
                  error: error.message,
                });
              });

            // Fetch SRT file
            if (data.srtFiles && data.srtFiles.wordLevel) {
              const srtRef = ref(storage, data.srtFiles.wordLevel);
              getDownloadURL(srtRef)
                .then((url) => {
                  if (isMounted) {
                    setWordSRTUrl(url);
                    amplitude.track('Word SRT URL Retrieved', { docId });
                  }
                })
                .catch((error) => {
                  console.error('Error fetching Word SRT URL:', error);
                  amplitude.track('Error Fetching Word SRT URL', {
                    docId,
                    error: error.message,
                  });
                });
            }
          }
        } else {
          setError('Recording details not found.');
          setLoading(false);
          amplitude.track('Error: Recording Details Not Found', { docId });
        }
      },
      (error) => {
        if (isMounted) {
          console.error('Error listening to recording details:', error);
          setError('Error fetching recording details');
          setLoading(false);
          amplitude.track('Error Listening to Recording Details', {
            docId,
            error: error.message,
          });
        }
      }
    );

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [docId]);

  const deleteRecording = async (recordingDetails) => {
    if (!recordingDetails?.filePath) return;
    try {
      const fileRef = ref(storage, recordingDetails.filePath);
      await deleteObject(fileRef);
      await deleteDoc(doc(db, 'recordings', docId));
      amplitude.track('Recording Deleted', { docId });
      navigate('/history');
    } catch (error) {
      console.error('Error deleting recording:', error);
      amplitude.track('Error Deleting Recording', { docId, error: error.message });
    }
  };

  // New function for updating any field(s) in the recording document
  const updateRecording = async (updates) => {
    try {
      await updateDoc(doc(db, 'recordings', docId), updates);
      amplitude.track('Recording Updated', { docId, updates });
    } catch (err) {
      console.error('Error updating recording:', err);
      amplitude.track('Error Updating Recording', { docId, error: err.message });
    }
  };

  return {
    recordingDetails,
    loading,
    error,
    audioUrl,
    wordSRTUrl,
    deleteRecording,
    updateRecording,
  };
}
