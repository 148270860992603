import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Snackbar,
  Alert,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';

const TranscriptionSection = ({
  title = 'Վերծանված տեքստ',
  transcription,
  copyStatus,
  onCopy,
  onSave
}) => {
  const [editOpen, setEditOpen] = useState(false);
  const [editedText, setEditedText] = useState(transcription || '');
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  if (!transcription) return null;

  const handleCopyClick = () => {
    if (onCopy) {
      onCopy(transcription);
    }
  };

  const handleEditOpen = () => {
    setEditedText(transcription);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleSave = () => {
    onSave(editedText);
    setEditOpen(false);
    setSuccessSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Button
          variant="outlined"
          onClick={handleCopyClick}
          startIcon={<ContentCopyIcon />}
        >
          {copyStatus}
        </Button>
        {onSave && (
          <Button variant="outlined" onClick={handleEditOpen} startIcon={<EditIcon />}>
            Խմբագրել
          </Button>
        )}
      </Box>

      <Paper
        variant="outlined"
        sx={{
          p: 3,
          backgroundColor: (theme) => theme.palette.grey[50],
          mt: 2
        }}
      >
        <Box
          sx={{
            fontSize: '1.1rem',
            lineHeight: '1.7',
            whiteSpace: 'normal',
            overflowWrap: 'break-word',
            wordBreak: 'break-word'
          }}
        >
          <ReactMarkdown remarkPlugins={[remarkBreaks]}>
            {transcription}
          </ReactMarkdown>
        </Box>
      </Paper>

      {onSave && (
        <Dialog
          open={editOpen}
          onClose={handleEditClose}
          fullScreen={fullScreen}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Խմբագրել տեքստը</DialogTitle>
          <DialogContent>
            <TextField
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
              multiline
              rows={fullScreen ? 12 : 15}
              variant="outlined"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditClose}>Չեղարկել</Button>
            <Button variant="contained" onClick={handleSave}>
              Պահպանել
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          Փոփոխությունը պահպանված է
        </Alert>
      </Snackbar>
    </>
  );
};

export default TranscriptionSection;
