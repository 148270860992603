import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const CtaBanner = ({ text, buttonText, onButtonClick, icon }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: { xs: 10, sm: 20 },
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#ffffff',
        padding: { xs: '10px 16px', sm: '12px 24px' },
        borderRadius: '12px',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        gap: { xs: 2, sm: 3 },
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
        maxWidth: '600px',
        width: { xs: '95%', sm: '90%' },
        border: '1px solid rgba(0, 0, 0, 0.06)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { xs: 1.5, sm: 3 },
          width: '100%',
        }}
      >
        {icon}
        <Typography
          variant="body1"
          sx={{
            color: '#2B2B2B',
            fontWeight: 500,
            fontSize: { xs: '0.875rem', sm: '1rem' },
            textAlign: { xs: 'left', sm: 'left' },
          }}
        >
          {text}
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={onButtonClick}
        sx={{
          backgroundColor: '#229ED9',
          '&:hover': { backgroundColor: '#1C8ABF' },
          color: '#ffffff',
          textTransform: 'none',
          fontWeight: 500,
          padding: { xs: '6px 16px', sm: '8px 24px' },
          borderRadius: '8px',
          boxShadow: 'none',
          whiteSpace: 'nowrap',
          width: { xs: '100%', sm: 'auto' },
          fontSize: { xs: '0.875rem', sm: '1rem' },
        }}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default CtaBanner;
