// src/pages/History.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  ListItemText,
  LinearProgress,
  Avatar,
  Chip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MicIcon from '@mui/icons-material/Mic';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { auth, db } from '../firebase';
import { collection, query, where, getDocs, limit, orderBy, startAfter } from 'firebase/firestore';
import * as amplitude from '@amplitude/analytics-browser';

const ITEMS_PER_PAGE = 10;

const History = () => {
  const [recordings, setRecordings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  const fetchRecordings = useCallback(async () => {
    if (!hasMore) return;
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        const recordingsRef = collection(db, 'recordings');
        let q = query(
          recordingsRef,
          where('userId', '==', user.uid),
          orderBy('createdAt', 'desc'),
          limit(ITEMS_PER_PAGE)
        );

        if (lastDoc) {
          q = query(
            recordingsRef,
            where('userId', '==', user.uid),
            orderBy('createdAt', 'desc'),
            startAfter(lastDoc),
            limit(ITEMS_PER_PAGE)
          );
        }

        const querySnapshot = await getDocs(q);
        const newRecordings = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setRecordings((prevRecordings) => {
          const ids = new Set(prevRecordings.map((item) => item.id));
          const filteredRecordings = newRecordings.filter((item) => !ids.has(item.id));
          return [...prevRecordings, ...filteredRecordings];
        });

        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(querySnapshot.docs.length === ITEMS_PER_PAGE);

        amplitude.track('Recordings Fetched', { count: newRecordings.length });
      }
    } catch (error) {
      console.error('Error fetching recordings:', error);
      amplitude.track('Fetch Recordings Failed', { error: error.message });
    } finally {
      setLoading(false);
    }
  }, [lastDoc, hasMore]);

  useEffect(() => {
    fetchRecordings();
  }, [fetchRecordings]);

  const lastRecordingElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        fetchRecordings();
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore, fetchRecordings]);

  const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours > 0 ? `${hours} ժ ` : ''}${minutes > 0 ? `${minutes} ր ` : ''}${secs} վրկ`;
  };

  const formatDate = (date) => {
    const createdDate = date.toDate();
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const time = createdDate.toLocaleTimeString('hy-AM', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    if (
      createdDate.getDate() === today.getDate() &&
      createdDate.getMonth() === today.getMonth() &&
      createdDate.getFullYear() === today.getFullYear()
    ) {
      return `Այսօր ${time}`;
    } else if (
      createdDate.getDate() === yesterday.getDate() &&
      createdDate.getMonth() === yesterday.getMonth() &&
      createdDate.getFullYear() === yesterday.getFullYear()
    ) {
      return `Երեկ ${time}`;
    }

    const day = String(createdDate.getDate()).padStart(2, '0');
    const month = String(createdDate.getMonth() + 1).padStart(2, '0');
    const year = createdDate.getFullYear();
    return `${day}.${month}.${year} ${time}`;
  };

  return (
    <Box sx={{ p: { xs: 1, sm: 3 }, height: 'calc(100vh - 64px)', overflowY: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Պատմություն
      </Typography>
      {loading && recordings.length === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <LinearProgress style={{ width: '100%' }} />
        </Box>
      )}
      {recordings.length > 0 ? (
        <Box sx={{ mb: 6 }}>
          {recordings.map((recording, index) => (
            <Link
              key={recording.id}
              to={`/recording-details/${encodeURIComponent(recording.id)}`}
              style={{ textDecoration: 'none', width: '100%' }}
              onClick={() => {
                amplitude.track('Recording Viewed', {
                  id: recording.id,
                  status: recording.status,
                  duration: recording.duration,
                });
              }}
            >
              <Card
                ref={index === recordings.length - 1 ? lastRecordingElementRef : null}
                variant="outlined"
                sx={{
                  mb: 1,
                  cursor: 'pointer',
                  p: { xs: 0.5, sm: 1 },
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: 'space-between',
                  width: '100%',
                  overflow: 'hidden',
                  maxWidth: '100%',
                }}
              >
                <CardContent sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Avatar sx={{ bgcolor: '#e0e0e0', mr: { xs: 0.5, sm: 1 } }}>
                    {recording.source === 'upload' ? (
                      <CloudUploadIcon color="disabled" />
                    ) : (
                      <MicIcon color="disabled" />
                    )}
                  </Avatar>
                  <ListItemText
                    primary={
                      recording.transcription
                        ? recording.transcription.slice(0, 100) + '...'
                        : recording.filePath
                        ? recording.filePath.split('/').pop()
                        : 'No File'
                    }
                    secondary={
                      <>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 0.5 }}>
                          <CalendarTodayIcon fontSize="small" sx={{ mr: 0.5 }} />
                          <Typography variant="body2" color="textSecondary" sx={{ mr: 1 }}>
                            {formatDate(recording.createdAt)}
                          </Typography>
                          <AccessTimeIcon fontSize="small" sx={{ mr: 0.5 }} />
                          <Typography variant="body2" color="textSecondary">
                            {formatDuration(recording.duration || 0)}
                          </Typography>
                        </Box>
                      </>
                    }
                    sx={{ flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}
                  />
                  <Chip
                    label={
                      recording.status === 'transcribed'
                        ? 'Վերծանված'
                        : recording.status === 'processing'
                        ? 'Ընթացքում է'
                        : 'Խափանված'
                    }
                    color={
                      recording.status === 'transcribed'
                        ? 'success'
                        : recording.status === 'processing'
                        ? 'warning'
                        : 'error'
                    }
                    sx={{
                      backgroundColor: recording.status === 'processing' ? '#ffa000' : undefined,
                      ml: { xs: 0, sm: 2 },
                      maxWidth: { xs: '100%', sm: 'auto' },
                    }}
                  />
                </CardContent>
              </Card>
            </Link>
          ))}
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <LinearProgress style={{ width: '100%' }} />
            </Box>
          )}
        </Box>
      ) : (
        <Typography>Ձայնագրություններ չկան</Typography>
      )}
    </Box>
  );
};

export default History;
